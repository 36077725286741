import Axios from "axios";
import { seesionExpired, accessDenied } from "../redux/appSettingsSlice";
import store from "../redux/store";
import { setCurrentUser } from "../redux/authSlice";

// Cache to store API responses
const apiCache: Record<string, any> = {};
const CACHE_TTL = 5 * 60 * 1000; // Cache TTL of 5 minutes

// Axios instance configuration
const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Function to create cache key from request URL and parameters
const createCacheKey = (config: any) => {
  const { url, params } = config;
  const queryString = params ? new URLSearchParams(params).toString() : "";
  const cacheKey = queryString ? `${url}?${queryString}` : url;
  return cacheKey;
};

// Nonce generation function
let timestamp: any = new Date();
timestamp = timestamp.toISOString();
const generateNonce = () => {
  const CryptoJS = require("crypto-js");
  const requestData = {};
  const hash = CryptoJS.SHA256(`${requestData}${timestamp}`);
  return hash.toString(CryptoJS.enc.Hex);
};

const nonce = generateNonce();

const cacheInvalidationMapping = {
  "/client": ["/client"],
  "/case/asset": ["/case/asset"],
  "/case/liability": ["/case/liability"],
  "/case/policy": ["/case/policy"],
  "/case/fund": ["/case/fund", "/case/valuation"],
  "/case/payments": ["/case/payments"],
  "/case/commission": ["/case/commission"],
  "/case/withdrawal": ["/case/withdrawal"],
  "/case/business": ["/case/business"],
  "/case/valuation": ["/case/valuation"],
  "/assystcashflow/income": ["/assystcashflow/income"],
  "/assystcashflow/expense": ["/assystcashflow/expense"],
  "/client/notes": ["/client/notes"],
  "/client/document": ["/client/document"],
  "/client/appointmentbycustomer": ["/client/appointmentbycustomer"],
  "/client/commissionbycustomer": ["/client/commissionbycustomer"],
  "/client/note/notebycustomerid": ["/client/note/notebycustomerid"],
  "/client/timeallocationbycustomer": ["/client/timeallocationbycustomer"],
  "/client/depentant": ["/client/depentant"],
  "/client/time": ["/client/time"],
  "/case/case-summary": ["/case/case-summary"],
  "/case/contact/customer": ["/case/contact/customer"],

  "masterdata/transactions/expensecategories": [
    "masterdata/transactions/expensecategories",
  ],
  "masterdata/attituderisk/categories": ["masterdata/attituderisk/categories"],
  "masterdata/attituderisk/ratings": ["masterdata/attituderisk/ratings"],
  "masterdata/objectives": ["masterdata/objectives"],
  "masterdata/standardtracking": ["masterdata/standardtracking"],
  "masterdata/userdefined/field": ["masterdata/userdefined/field"],

  "masterdata/providers": ["masterdata/providers"],
  "masterdata/commission/commissionrule": [
    "masterdata/commission/commissionrule",
  ],
  "masterdata/commission/commissiontypes": [
    "masterdata/commission/commissiontypes",
  ],
  user: ["user"],
  "user/info": ["user/info"],
  "user/auth": ["user/auth"],
  "user/auth/roles": ["user/auth/roles"],
  "user/limit-user": ["user/limit-user"],
  "/option?": ["/option?"],
  "/masterdata/delete/objective": ["masterdata/objectives"],
  "/client/search": ["/client/search"],
  "/report": ["/report"],
  "masterdata/transactions/incomecategories": [
    "masterdata/transactions/incomecategories",
  ],
};

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config: any) => {
    const cacheKey = createCacheKey(config);
    if (config.method === "get") {
      const cachedResponse = apiCache[cacheKey];
      if (cachedResponse) {
        const { data, expiration } = cachedResponse;
        if (Date.now() < expiration) {
          // Cache hit, return cached data
          return Promise.reject({
            config,
            data,
            status: 200,
            statusText: "OK",
            headers: {},
            request: {},
            fromCache: true,
          });
        } else {
          delete apiCache[cacheKey]; // Cache expired, delete it
        }
      }
    }

    // Handle cache invalidation for specific methods
    const cacheInvalidatingMethods = ["patch", "post", "delete"];
    if (cacheInvalidatingMethods.includes(config.method.toLowerCase())) {
      const { url } = config;

      Object.entries(cacheInvalidationMapping).forEach(
        ([path, keysToInvalidate]) => {
          if (url.toLowerCase().startsWith(path.toLowerCase())) {
            keysToInvalidate.forEach((key) => {
              if (apiCache[key]) {
                delete apiCache[key];
              }
            });
          }
        }
      );
    }

    // Add authorization and headers
    const token = store.getState().authUser.userToken;
    const userId = store.getState().authUser.userId;
    const refreshToken = store.getState().authUser.refreshToken;

    if (!(config.data instanceof FormData)) {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["X-Nonce"] = nonce;
    config.headers["X-Timestamp"] = timestamp;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (userId) {
      config.headers.UserId = userId;
    }
    if (refreshToken) {
      config.headers.RefreshToken = refreshToken;
    }
    return config; // Don't forget to return the config object
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.config.method === "get") {
      const cacheKey = createCacheKey(response.config);
      const expiration = Date.now() + CACHE_TTL;
      apiCache[cacheKey] = {
        data: response.data,
        expiration,
      };
    }

    // Handle authorization header and store the token
    let authorizationHeader =
      response.headers["authorization"] ||
      response.headers["x-amzn-remapped-authorization"];
    if (authorizationHeader?.startsWith("Bearer ")) {
      store.dispatch(
        setCurrentUser(authorizationHeader.replace("Bearer ", ""))
      );
    }
    return response;
  },
  (error) => {
    if (error.fromCache) {
      return Promise.resolve({
        data: error.data,
        status: error.status,
        statusText: error.statusText,
        config: error.config,
        headers: error.headers,
        request: error.request,
      });
    }
    if (error.response?.status === 401) store.dispatch(seesionExpired(true));
    else if (error.response?.status === 403) store.dispatch(accessDenied(true));
    return Promise.reject(error);
  }
);

export default axiosInstance;

// Public instance for unauthenticated requests
export const axiosPublicInstance = Axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_URL,
});
