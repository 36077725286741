import * as React from "react";
import {
  Tree,
  TreeItem,
  TreeItemLayout,
  Spinner,
} from "@fluentui/react-components";
import apiServices from "../../service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setHeaderValue } from "../../redux/headerValue";
import { setTableData } from "../../redux/clientSlice";
import { updateTableDataList } from "../../redux/tableDataSlice";
import { setLoadingTableData } from "../../redux/loader";

export const DynamicTree = () => {
  const [selectedItem, setSelectedItem] = React.useState<string | null>(null);
  const [treeValue, setTreeValue] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const clientValue = useSelector(
    (state: RootState) => state.clientid.storedValue
  );
  const tableDataList = useSelector(
    (state: RootState) => state.tableDatas.tableDataList
  );

  const TreeData = () => {
    setLoading(true);
    const id = clientValue;

    apiServices.ClientPartnerSplit.getList(id)
      .then((res) => {
        const filteredData =
          res?.data?.filter(
            (item: any) =>
              item.TableName !== "Customer" &&
              item.TableName !== "Business" &&
              item.TableName !== "Valuation" &&
              item.TableName !== "Withdrawal" &&
              item.TableName !== "Appointment" &&
              item.TableName !== "Fund" &&
              // item.TableName !== "Note" &&
              item.TableName !== "Commission" &&
              item.TableName !== "Payment"
          ) || [];
        setTreeValue(filteredData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    TreeData();
  }, []);

  const handleClick = (tableName: string, count: number) => {
    setSelectedItem(tableName);
    dispatch(setHeaderValue(tableName));
    handleFetchData(tableName);
  };
  React.useEffect(() => {
    if (treeValue.length > 0) {
      const firstItem = treeValue[0];
      setSelectedItem(firstItem.TableName);
      dispatch(setHeaderValue(firstItem.TableName));
      handleFetchData(firstItem.TableName);
    }
  }, [treeValue]);

  const tableDataListing = async (tableName: string) => {
    try {
      const id = clientValue;
      let response;
      switch (tableName) {
        case "Asset":
          response = await apiServices.assets.getAssets(id);
          return response.data.data.map((item: any) => ({
            bank_account_number: item.bank_account_number,
            bank_account_type: item.bank_account_type,
            bank_contribution: item.bank_contribution,
            bank_date_opened: item.bank_date_opened,
            bank_frequency: item.bank_frequency,
            bank_interest_rate: item.bank_interest_rate,
            recordId: item.asset_id,
          }));

        case "Liability":
          response = await apiServices.liability.getLiabilities(id);
          return response.data.data.map((item: any) => ({
            borrower: item.borrower,
            liability_type: item.liability_type,
            provider: item.provider,
            status: item.status,
            recordId: item.liability_id,
          }));

        case "Policy":
          response = await apiServices.policy.getPolicies(id);
          return response.data.data.map((item: any) => ({
            assured_detail: item.assured_detail,
            assured_name1: item.assured_name1,
            recordId: item.policy_id,
            provider: item.provider,
          }));

        case "Income":
          response = await apiServices.income.getList(id);
          return response.data.data.result.map((item: any) => ({
            recordId: item.income_id,
            income_type: item.income_type,
            owner: item.owner,
            frequency: item.frequency,
            source: item.source,
          }));

        case "Expense":
          response = await apiServices.outgoings.getList(id);
          return response.data.data.result.map((item: any) => ({
            recordId: item.expense_id,
            client_amount: item.client_amount,
            partner_amount: item.partner_amount,
            joint_amount: item.joint_amount,
            frequency: item.frequency,
          }));

        case "Dependant":
          response = await apiServices.dependant.getList(id);
          return response.data.data.map((item: any) => ({
            recordId: item.dependant_id,
            first_names: item.first_names,
            last_name: item.last_name,
            financially_dependant: item.financially_dependant,
            known_as: item.known_as,
          }));

        case "Address":
          response= await apiServices.addressBook.getList(id);
          return response.data.data.map((item:any)=>({
            recordId:item.address_id,
            address_type:item.address_type,
            organisation:item.organisation,
            contact_name:item.contact_name,
            address_1:item.address_1,
            address_2:item.address_2,
            address_3:item.address_3,
            postcode:item.postcode,
          }));

          case "Note":
            response = await apiServices.factFindNote.getList(id);
            return response.data.data((item:any)=>({
              recordId:item.note_id,
              note_text:item.note_text,
              note_time:item.note_time,
              note_type:item.note_type
            }));

        default:
          console.error("Invalid Table Name:", tableName);
          return [];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };
  const handleFetchData = async (tableName: string) => {
    dispatch(setLoadingTableData(true));
    try {
      const result = await tableDataListing(tableName);
      if (result.length > 0) {
        dispatch(updateTableDataList({ tableName, data: result }));
        dispatch(setTableData(result));
      } else {
        console.warn("No data to display for:", tableName);
      }
    } catch (error) {
      console.error("Error in handleFetchData:", error);
    } finally {
      dispatch(setLoadingTableData(false));
    }
  };

  return (
    <div>
      {loading ? (
        <div className="spinner-container-tree">
          <Spinner size="medium" />
        </div>
      ) : (
        <div>
          <Tree aria-label="Dynamic Tree">
            {treeValue?.map((item: any, index: number) => (
              <TreeItem key={index} itemType="leaf">
                <TreeItemLayout
                  onClick={() => handleClick(item.TableName, item.Count)}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      selectedItem === item.TableName
                        ? "#e0f7fa"
                        : "transparent",
                  }}
                >
                  {item.TableName} ({item.Count})
                </TreeItemLayout>
              </TreeItem>
            ))}
          </Tree>
        </div>
      )}
    </div>
  );
};
