import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoadingState {
  loadingTableData: boolean;
}

const initialState: LoadingState = {
  loadingTableData: false,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoadingTableData: (state, action: PayloadAction<boolean>) => {
      state.loadingTableData = action.payload;
    },
  },
});

export const { setLoadingTableData } = loadingSlice.actions;
export default loadingSlice.reducer;
