import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
} from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import DataSelection, { ResponseItem, TableNameCounts } from "./DataSelection";
import ChangesCP from "./ChangesCP";
import SecondFile from "./Selection";
import Introduction from "./Intoduction";
import FinalSection from "./FinalSection";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { setHeaderValue } from "../../redux/headerValue";
import { updateTableDataList } from "../../redux/tableDataSlice";
import { setTableData } from "../../redux/clientSlice";
import { setDynamicResponseData } from "../../redux/dynamicResponse";

interface CPS {
  isOpen: boolean;
  onClose: () => void;
  ClientPartner: any;
  partnerID: any;
}
const MainPage: React.FC<CPS> = ({
  isOpen,
  onClose,
  ClientPartner,
  partnerID,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [selectedTable, setSelectedTable] = useState<string | null>(null);
  const [clientData, setClientData] = useState<ResponseItem[]>([]);
  const [partnerData, setPartnerData] = useState<ResponseItem[]>([]);
  const [tableNameCounts, setTableNameCounts] = useState<TableNameCounts>({
    Client: {},
    Partner: {},
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [getPartner, setGetPartner] = useState<any>(null);
  const [selectedReason, setSelectedReason] = useState<string | undefined>();
  const [checkboxState, setCheckboxState] = useState<{
    client: boolean;
    partner: boolean;
  }>({ client: false, partner: false });
  const [active, setActive] = useState<
    "FIRST" | "SECOND" | "THIRD" | "FOURTH" | "FIFTH" | null
  >("FIRST");
  const nextToSelect = () => {
    setActive("SECOND");
  };
  const nextToDetails = () => {
    setActive("THIRD");
  };
  const NextToDataSelection = () => {
    setActive("FOURTH");
  };
  const handleClose = () => {
    onClose();
    setSelectedReason("");
    setCheckboxState({ client: false, partner: false });
    dispatch(setHeaderValue(""));
    dispatch(
      updateTableDataList({
        tableName: "",
        data: [],
      })
    );
    dispatch(setTableData([]));
    dispatch(
      setDynamicResponseData({
        asset: [],
        liability: [],
        policy: [],
        income: [],
        expense: [],
        dependant:[],
        addressbook:[]

      })
    );
    setSelectedTable(null);
    setClientData([]);
    setPartnerData([]);
    setTableNameCounts({
      Client: {},
      Partner: {},
    });
  };
  const handleCancelIntro = () => {
    onClose();
    setSelectedReason("");
    setCheckboxState({ client: false, partner: false });
    dispatch(setHeaderValue(""));
    dispatch(
      updateTableDataList({
        tableName: "",
        data: [],
      })
    );
    dispatch(setTableData([]));
    dispatch(
      setDynamicResponseData({
        asset: [],
        liability: [],
        policy: [],
        income: [],
        expense: [],
        dependant:[],
        addressbook:[]
      })
    );
    setSelectedTable(null);
    setClientData([]);
    setPartnerData([]);
    setTableNameCounts({
      Client: {},
      Partner: {},
    });
  };
  const BackToIntro = () => {
    setActive("FIRST");
  };
  const CancelSelection = () => {
    onClose();
    setSelectedReason("");
    setCheckboxState({ client: false, partner: false });
    dispatch(setHeaderValue(""));
    dispatch(
      updateTableDataList({
        tableName: "",
        data: [],
      })
    );
    dispatch(setTableData([]));
    dispatch(
      setDynamicResponseData({
        asset: [],
        liability: [],
        policy: [],
        income: [],
        expense: [],
        dependant:[],
        addressbook:[]
      })
    );
    setSelectedTable(null);
    setClientData([]);
    setPartnerData([]);
    setTableNameCounts({
      Client: {},
      Partner: {},
    });
  };
  const BackToSecond = () => {
    setActive("SECOND");
  };
  const CancelCP = () => {
    onClose();
    setSelectedReason("");
    setCheckboxState({ client: false, partner: false });
    dispatch(setHeaderValue(""));
    dispatch(
      updateTableDataList({
        tableName: "",
        data: [],
      })
    );
    dispatch(setTableData([]));
    dispatch(
      setDynamicResponseData({
        asset: [],
        liability: [],
        policy: [],
        income: [],
        expense: [],
        dependant:[],
        addressbook:[]
      })
    );
    setSelectedTable(null);
    setClientData([]);
    setPartnerData([]);
    setTableNameCounts({
      Client: {},
      Partner: {},
    });
  };
  const BackToCP = () => {
    setActive("THIRD");
  };
  const CancelDataSelection = () => {
    onClose();
    setSelectedReason("");
    setCheckboxState({ client: false, partner: false });
    dispatch(setHeaderValue(""));
    dispatch(
      updateTableDataList({
        tableName: "",
        data: [],
      })
    );
    dispatch(setTableData([]));
    dispatch(
      setDynamicResponseData({
        asset: [],
        liability: [],
        policy: [],
        income: [],
        expense: [],
        dependant:[],
        addressbook:[]
      })
    );
    setSelectedTable(null);
    setClientData([]);
    setPartnerData([]);
    setTableNameCounts({
      Client: {},
      Partner: {},
    });
  };
  const cancelSplitData = () => {
    onClose();
    setSelectedReason("");
    setCheckboxState({ client: false, partner: false });
    dispatch(setHeaderValue(""));
    dispatch(
      updateTableDataList({
        tableName: "",
        data: [],
      })
    );
    dispatch(setTableData([]));
    dispatch(
      setDynamicResponseData({
        asset: [],
        liability: [],
        policy: [],
        income: [],
        expense: [],
        dependant:[],
        addressbook:[]
      })
    );
    setSelectedTable(null);
    setClientData([]);
    setPartnerData([]);
    setTableNameCounts({
      Client: {},
      Partner: {},
    });
  };
  const resetState = () => {
    setActive("FIRST");
  };
  const NextToSplit = () => {
    setActive("FIFTH");
  };
  const BackToDataSelection = () => {
    setActive("FOURTH");
  };
  useEffect(() => {
    if (isOpen) {
      resetState();
    }
  }, [isOpen]);
  return (
    <div>
      {" "}
      <Dialog
        modalType="alert"
        open={isOpen}
        onOpenChange={(event, data) => {
          if (!data.open) handleClose();
        }}
      >
        <DialogSurface style={{ minWidth: "800px" }}>
          <DialogBody>
            <DialogContent>
              {" "}
              {active === "FIRST" && (
                <Introduction
                  nextToSelect={nextToSelect}
                  handleCancelIntro={handleCancelIntro}
                />
              )}
              {active === "SECOND" && (
                <SecondFile
                  nextToDetails={nextToDetails}
                  BackToIntro={BackToIntro}
                  CancelSelection={CancelSelection}
                  selectedReason={selectedReason}
                  setSelectedReason={setSelectedReason}
                  checkboxState={checkboxState}
                  setCheckboxState={setCheckboxState}
                />
              )}
              {active === "THIRD" && (
                <ChangesCP
                  NextToDataSelection={NextToDataSelection}
                  CancelCP={CancelCP}
                  BackToSecond={BackToSecond}
                  ClientPartner={ClientPartner}
                  partnerID={partnerID}
                  getPartner={getPartner}
                  setGetPartner={setGetPartner}
                />
              )}
              {active === "FOURTH" && (
                <DataSelection
                  CancelDataSelection={CancelDataSelection}
                  BackToCP={BackToCP}
                  NextToSplit={NextToSplit}
                  checkboxState={checkboxState}
                  getPartner={getPartner}
                  setGetPartner={setGetPartner}
                  ClientPartner={ClientPartner}
                  selectedTable={selectedTable}
                  setSelectedTable={setSelectedTable}
                  clientData={clientData}
                  setClientData={setClientData}
                  partnerData={partnerData}
                  setPartnerData={setPartnerData}
                  tableNameCounts={tableNameCounts}
                  setTableNameCounts={setTableNameCounts}
                />
              )}
              {active === "FIFTH" && (
                <FinalSection
                  cancelSplitData={cancelSplitData}
                  checkboxState={checkboxState}
                  BackToDataSelection={BackToDataSelection}
                  onClose={onClose}
                  setSelectedValues={setSelectedValues}
                  setClientData={setClientData}
                  setPartnerData={setPartnerData}
                  setTableNameCounts={setTableNameCounts}
                  tableNameCounts={tableNameCounts}
                  setCheckboxState={setCheckboxState}
                  setSelectedReason={setSelectedReason}
                />
              )}
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default MainPage;
