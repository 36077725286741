import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  type MRT_ColumnDef,
} from "material-react-table";
import {
  Button,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  makeStyles,
  Divider,
} from "@fluentui/react-components";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import {
  DocumentBulletList20Regular,
  Dismiss24Regular,
} from "@fluentui/react-icons";

import apiServices from "../../service";
import {
  camalize,
  convertStringToArray,
  formatedDate,
  formatedTime,
  getClientType,
  getYes_NoConversion,
  isObjectEmpty,
} from "../../utils";
import ReactQuillComponent from "../../components/ReactQuillComponent";
import { debounce } from "lodash";
import { title } from "process";
import {
  banksBSocieties,
  clientDetails,
  creditCards,
  General,
  healthAssurance,
  homePersonal,
  incomeProtection,
  Investments,
  lifeAssurance,
  loansLeaseHP,
  Mortages,
  pensions,
  savingsPlans,
  Shares,
  unitLinked,
} from "../../shared/actionlistDetails";
interface PropsType {
  [x: string]: any;
}
const useStyles = makeStyles({
  divider: {
    flexGrow: 0,
  },
});
interface RowItem {
  case_type: string;
  business_category: number;
  itemId: string;
  customer_id: string;
}
function ActionDataList(props: PropsType) {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [activeRowItem, setActiveRowItem] = useState<any | {}>({});
  const [activeRowItemDetail, setActiveRowItemDetail] = useState<any | {}>({});

  const hasSearched = useRef(false);

  useEffect(() => {
    const debouncedFunction = debounce(() => {
      if (props.search.length >= 2) {
        hasSearched.current = true;
        props.getActionListData();
      } else if (props.search === "" && hasSearched.current) {
        props.getActionListData();
        hasSearched.current = false;
      }
    }, 300);
    debouncedFunction();
    return () => {
      debouncedFunction.cancel();
    };
  }, [props.search]);

  useEffect(() => {
    if (
      props.actionListItems.length > 0 &&
      Object.keys(rowSelection)[0] !== undefined
    ) {
      let index = Object.keys(rowSelection)[0];
      let selectedRow = props.actionListItems[index];
      setActiveRowItem(selectedRow);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (!isObjectEmpty(activeRowItem)) {
      if (
        activeRowItem.category === "Client Action" ||
        activeRowItem.category === "Client Review" ||
        activeRowItem.category === "Birthday"
      ) {
        getCustomerDetail(activeRowItem.customer_id);
      } else if (activeRowItem.category === "Case Tracking Action") {
        getCaseActionDetails(activeRowItem);
      } else {
        getCaseDetail(activeRowItem);
      }
    }
  }, [activeRowItem]);

  const fetchDetail = (
    apiCall: (itemId: string, customerId: string) => Promise<any>,
    itemId: string,
    customerId: string
  ) => {
    apiCall(itemId, customerId)
      .then(handleSuccessResponse)
      .catch(handleErrorResponse);
  };

  const fetchClientDetail = (
    apiCall: (customerId: string) => Promise<any>,
    customerId: string
  ) => {
    apiCall(customerId)
      .then((response: any) => {
        if (
          response.data &&
          !response.data.isError &&
          !isObjectEmpty(response.data.data)
        ) {
          setActiveRowItemDetail(response.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const caseTypeMap: {
    [key: string]: (itemId: string, customerId: string) => Promise<any>;
  } = {
    "Unit-linked": apiServices.assets.getDetail,
    Shares: apiServices.assets.getDetail,
    "Banks/B Societies": apiServices.assets.getDetail,
    "Home/Personal": apiServices.assets.getDetail,
    Mortgages: apiServices.liability.getDetail,
    "Loans/Lease/HP": apiServices.liability.getDetail,
    "Credit Cards": apiServices.liability.getDetail,
    Pensions: apiServices.policy.getDetail,
    "Health Assurance": apiServices.policy.getDetail,
    "Life Assurance": apiServices.policy.getDetail,
    "Savings Plans": apiServices.policy.getDetail,
    Investments: apiServices.policy.getDetail,
    General: apiServices.policy.getDetail,
    "Income Protection": apiServices.policy.getDetail,
  };

  const businessCategoryMap: {
    [key: number]: (itemId: string, customerId: string) => Promise<any>;
  } = {
    2: apiServices.assets.getDetail,
    6: apiServices.liability.getDetail,
    7: apiServices.liability.getDetail,
    1: apiServices.policy.getDetail,
  };

  const getCaseDetail = (rowItem: RowItem) => {
    const apiCall = caseTypeMap[rowItem.case_type];
    if (apiCall) {
      fetchDetail(apiCall, rowItem.itemId, rowItem.customer_id);
    } else {
      console.error("No matching case_type found");
    }
  };

  const getCaseActionDetails = (rowItem: RowItem) => {
    const apiCall = businessCategoryMap[rowItem.business_category];
    if (apiCall) {
      fetchDetail(apiCall, rowItem.itemId, rowItem.customer_id);
    } else {
      console.error("No matching business_category found");
    }
  };

  const getCustomerDetail = (customerId: string) => {
    fetchClientDetail(apiServices.client.getDetail, customerId);
  };

  const renderClientType = (type: number) => (type ? getClientType(type) : "-");
  const renderDate = (date: string) => (date ? formatedDate(date) : "-");
  const renderRichText = (text: string) =>
    text ? <ReactQuillComponent value={text} /> : "-";
  const renderYesorNo = (value: number) =>
    value ? getYes_NoConversion(value) : "-";

  const handleSuccessResponse = (response: any) => {
    if (
      response.data &&
      !response.data.isError &&
      !isObjectEmpty(response.data.data)
    ) {
      setActiveRowItemDetail(response.data.data);
    }
  };
  const handleErrorResponse = (err: any) => {
    console.log(err);
  };
  const Details = (activeRowItemDetail: any) => {
    if (
      activeRowItem.category === "Policy Review" ||
      (activeRowItem.business_category === 1 &&
        activeRowItem.category === "Case Tracking Action")
    ) {
      if (activeRowItem.case_type === "Life Assurance") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              lifeAssurance.length > 0 &&
              lifeAssurance.map((policyLA: any) => (
                <div key={policyLA.lifeAssurance_id} className="details">
                  <p className="details_label">{policyLA.label} </p>
                  <p>
                    {(() => {
                      switch (policyLA.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "mortgage_link":
                          return renderYesorNo(
                            activeRowItemDetail.mortgage_link
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "valuation_date":
                          return renderDate(activeRowItemDetail.valuation_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "in_trust_note":
                          return renderRichText(
                            activeRowItemDetail.in_trust_note
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return activeRowItemDetail[policyLA.value] || "-";
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeRowItem.case_type === "Pensions") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              pensions.length > 0 &&
              pensions.map((policypensions: any) => (
                <div key={policypensions.lifeAssurance_id} className="details">
                  <p className="details_label">{policypensions.label} </p>
                  <p>
                    {(() => {
                      switch (policypensions.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "valuation_date":
                          return renderDate(activeRowItemDetail.valuation_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "in_trust_note":
                          return renderRichText(
                            activeRowItemDetail.in_trust_note
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return (
                            activeRowItemDetail[policypensions.value] || "-"
                          );
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeRowItem.case_type === "Savings Plans") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              savingsPlans.length > 0 &&
              savingsPlans.map((policySP: any) => (
                <div key={policySP.lifeAssurance_id} className="details">
                  <p className="details_label">{policySP.label} </p>
                  <p>
                    {(() => {
                      switch (policySP.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "valuation_date":
                          return renderDate(activeRowItemDetail.valuation_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "in_trust_note":
                          return renderRichText(
                            activeRowItemDetail.in_trust_note
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return activeRowItemDetail[policySP.value] || "-";
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeRowItem.case_type === "Income Protection") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              incomeProtection.length > 0 &&
              incomeProtection.map((policyIP: any) => (
                <div key={policyIP.lifeAssurance_id} className="details">
                  <p className="details_label">{policyIP.label} </p>
                  <p>
                    {(() => {
                      switch (policyIP.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "valuation_date":
                          return renderDate(activeRowItemDetail.valuation_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "in_trust_note":
                          return renderRichText(
                            activeRowItemDetail.in_trust_note
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return activeRowItemDetail[policyIP.value] || "-";
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeRowItem.case_type === "Health Assurance") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              healthAssurance.length > 0 &&
              healthAssurance.map((policyHA: any) => (
                <div key={policyHA.healthAssurance_id} className="details">
                  <p className="details_label">{policyHA.label} </p>
                  <p>
                    {(() => {
                      switch (policyHA.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return activeRowItemDetail[policyHA.value] || "-";
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeRowItem.case_type === "General") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              General.length > 0 &&
              General.map((policyGeneral: any) => (
                <div key={policyGeneral.lifeAssurance_id} className="details">
                  <p className="details_label">{policyGeneral.label} :</p>
                  <p>
                    {(() => {
                      switch (policyGeneral.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "in_trust_note":
                          return renderRichText(
                            activeRowItemDetail.in_trust_note
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return (
                            activeRowItemDetail[policyGeneral.value] || "-"
                          );
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeRowItem.case_type === "Investments") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              Investments.length > 0 &&
              Investments.map((policyIInvestments: any) => (
                <div
                  key={policyIInvestments.Investments_id}
                  className="details"
                >
                  <p className="details_label">{policyIInvestments.label} </p>
                  <p>
                    {(() => {
                      switch (policyIInvestments.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "valuation_date":
                          return renderDate(activeRowItemDetail.valuation_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "in_trust_note":
                          return renderRichText(
                            activeRowItemDetail.in_trust_note
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return (
                            activeRowItemDetail[policyIInvestments.value] || "-"
                          );
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      }
    } else if (
      activeRowItem.case_type === "Unit-linked" ||
      (activeRowItem.business_category === 2 &&
        activeRowItem.category === "Case Tracking Action")
    ) {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            unitLinked.length > 0 &&
            unitLinked.map((investmentdata: any) => (
              <div key={investmentdata.lifeAssurance_id} className="details">
                <p className="details_label">{investmentdata.label} :</p>
                <p>
                  {(() => {
                    switch (investmentdata.value) {
                      case "policy_holder":
                        return renderClientType(
                          activeRowItemDetail.policy_holder
                        );
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "provider_note":
                        return renderRichText(
                          activeRowItemDetail.provider_note
                        );
                      case "admin_note":
                        return renderRichText(activeRowItemDetail.admin_note);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return activeRowItemDetail[investmentdata.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeRowItem.case_type === "Shares") {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            Shares.length > 0 &&
            Shares.map((Sharedata: any) => (
              <div key={Sharedata.Shares_id} className="details">
                <p className="details_label">{Sharedata.label} </p>
                <p>
                  {(() => {
                    switch (Sharedata.value) {
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "value_date":
                        return renderDate(activeRowItemDetail.value_date);
                      case "share_date_acquired":
                        return renderDate(
                          activeRowItemDetail.share_date_acquired
                        );
                      case "provider_note":
                        return renderRichText(
                          activeRowItemDetail.provider_note
                        );
                      case "withdrawal_note":
                        return renderRichText(
                          activeRowItemDetail.withdrawal_note
                        );
                      case "admin_note":
                        return renderRichText(activeRowItemDetail.admin_note);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return activeRowItemDetail[Sharedata.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeRowItem.case_type === "Banks/B Societies") {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            banksBSocieties.length > 0 &&
            banksBSocieties.map((Banksdata: any) => (
              <div key={Banksdata.lifeAssurance_id} className="details">
                <p className="details_label">{Banksdata.label} </p>
                <p>
                  {(() => {
                    switch (Banksdata.value) {
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "value_date":
                        return renderDate(activeRowItemDetail.value_date);
                      case "bank_date_opened":
                        return renderDate(activeRowItemDetail.bank_date_opened);
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "provider_note":
                        return renderRichText(
                          activeRowItemDetail.provider_note
                        );
                      case "withdrawal_note":
                        return renderRichText(
                          activeRowItemDetail.withdrawal_note
                        );
                      case "note_text":
                        return renderRichText(activeRowItemDetail.note_text);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return activeRowItemDetail[Banksdata.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeRowItem.case_type === "Home/Personal") {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            homePersonal.length > 0 &&
            homePersonal.map((homePersonaldata: any) => (
              <div key={homePersonaldata.Home_Personal_id} className="details">
                <p className="details_label">{homePersonaldata.label} </p>
                <p>
                  {(() => {
                    switch (homePersonaldata.value) {
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "value_date":
                        return renderDate(activeRowItemDetail.value_date);
                      case "home_date_acquired":
                        return renderDate(
                          activeRowItemDetail.home_date_acquired
                        );
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "note_text":
                        return renderRichText(activeRowItemDetail.note_text);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return (
                          activeRowItemDetail[homePersonaldata.value] || "-"
                        );
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (
      activeRowItem.case_type === "Mortgages" ||
      (activeRowItem.business_category === 6 &&
        activeRowItem.category === "Case Tracking Action")
    ) {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            Mortages.length > 0 &&
            Mortages.map((Mortagesdata: any) => (
              <div key={Mortagesdata.Mortages_id} className="details">
                <p className="details_label">{Mortagesdata.label} </p>
                <p>
                  {(() => {
                    switch (Mortagesdata.value) {
                      case "joint_indicator":
                        return renderClientType(
                          activeRowItemDetail.joint_indicator
                        );
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "amount_outstanding_date":
                        return renderDate(
                          activeRowItemDetail.amount_outstanding_date
                        );
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "provider_note":
                        return renderRichText(
                          activeRowItemDetail.provider_note
                        );
                      case "note_text":
                        return renderRichText(activeRowItemDetail.note_text);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return activeRowItemDetail[Mortagesdata.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (
      activeRowItem.case_type === "Loans/Lease/HP" ||
      (activeRowItem.business_category === 7 &&
        activeRowItem.category === "Case Tracking Action")
    ) {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            loansLeaseHP.length > 0 &&
            loansLeaseHP.map((loandata: any) => (
              <div key={loandata.Loans_Lease_HP_id} className="details">
                <p className="details_label">{loandata.label} </p>
                <p>
                  {(() => {
                    switch (loandata.value) {
                      case "joint_indicator":
                        return renderClientType(
                          activeRowItemDetail.joint_indicator
                        );
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "amount_outstanding_date":
                        return renderDate(
                          activeRowItemDetail.amount_outstanding_date
                        );
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "provider_note":
                        return renderRichText(
                          activeRowItemDetail.provider_note
                        );
                      case "note_text":
                        return renderRichText(activeRowItemDetail.note_text);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return activeRowItemDetail[loandata.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeRowItem.case_type === "Credit Cards") {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            creditCards.length > 0 &&
            creditCards.map((creditCard: any) => (
              <div key={creditCard.lifeAssurance_id} className="details">
                <p className="details_label">{creditCard.label} </p>
                <p>
                  {(() => {
                    switch (creditCard.value) {
                      case "joint_indicator":
                      case "joint_indicator":
                        return renderClientType(
                          activeRowItemDetail.joint_indicator
                        );
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "amount_outstanding_date":
                        return renderDate(
                          activeRowItemDetail.amount_outstanding_date
                        );
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "provider_note":
                        return renderRichText(
                          activeRowItemDetail.provider_note
                        );
                      case "note_text":
                        return renderRichText(activeRowItemDetail.note_text);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return activeRowItemDetail[creditCard.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (
      activeRowItem.category === "Client Review" ||
      activeRowItem.category === "Client Action" ||
      activeRowItem.category === "Birthday"
    ) {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            clientDetails.length > 0 &&
            clientDetails.map((clientDetail: any) => (
              <div key={clientDetail.client_id} className="details">
                <p className="details_label">{clientDetail.label} </p>
                <p>
                  {(() => {
                    switch (clientDetail.value) {
                      case "date_of_birth":
                        return renderDate(
                          activeRowItemDetail.Personal.date_of_birth
                        );
                      case "review_note":
                        return renderRichText(activeRowItemDetail.review_note);
                      default:
                        return (
                          activeRowItemDetail.NameAndAddress[
                            clientDetail.value
                          ] ||
                          activeRowItemDetail.Contact[clientDetail.value] ||
                          activeRowItemDetail.Nationality[clientDetail.value] ||
                          activeRowItemDetail.Personal[clientDetail.value] ||
                          "-"
                        );
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else {
      return null;
    }
  };
  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.date)}</>,
      },
      {
        accessorKey: "time",
        header: "Time",
        Cell: ({ row }: any) => <>{formatedTime(row.original.time)}</>,
      },
      {
        accessorKey: "first_names",
        header: "Customer",
        Cell: ({ row }: any) => (
          <>
            {row.original.last_name}, {row.original.first_names}
          </>
        ),
      },

      {
        accessorKey: "home_phone",
        header: "Home Phone",
      },
      {
        accessorKey: "work_phone",
        header: "Work Phone",
      },
      {
        accessorKey: "category",
        header: "Category",
      },
      // {
      //   accessorKey: "category",
      //   header: "Category",
      //   Cell: ({ row }: any) => <>{getCategory(row.original.category)}</>,
      // },
      {
        accessorKey: "type",
        header: "Type",
      },
      {
        accessorKey: "description",
        header: "Description",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.description} />
          </>
        ),
      },
      {
        accessorKey: "assigned",
        header: "Assigned",
      },
      {
        accessorKey: "consultant",
        header: "Consultant",
      },
    ],
    []
  );
  function getLabel(caseType: any) {
    switch (caseType) {
      case "Banks/B Societies":
        return "Asset Review";
      case "Home/Personal":
        return "Asset Review";
      case "Unit-linked":
        return "Asset Review";
      case "Shares":
        return "Asset Review";
      case "Credit Cards":
        return "Liability Review";
      case "Loans/Lease/HP":
        return "Liability Review";
      case "Mortgages":
        return "Liability Review";

      default:
        return null;
    }
  }
  function getCategoryLabel(category: any) {
    switch (category) {
      case "Client Action":
        return "Client Action";
      case "Client Review":
        return "Client Review";
      case "Case Tracking Action":
        return "Case Action";
      case "Asset (Investment) Review":
        return "Asset Review";
      case "Asset (Shares)Review":
        return "Asset Review";
      case "Asset (Bank) Review":
        return "Asset Review";
      case "Asset (Home)Review":
        return "Asset Review";
      case "Mortgages Review Date":
        return "Liability Review";
      case "Loan Review Date":
        return "Liability Review";
      case "Credit Cards":
        return "Liability Review";
      case "Birthday":
        return "Client Detail";
      default:
        return "Policy Review";
    }
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        {!convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
          "actionlist_detail_btn"
        ) ? (
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
            icon={<DocumentBulletList20Regular />}
            style={{ width: "fit-content" }}
            disabled={isObjectEmpty(activeRowItem) ? true : false}
          >
            Details
          </Button>
        ) : (
          ""
        )}
      </div>

      <div className="client-list-wrapper">
        <MaterialReactTable
          columns={columns}
          data={props.actionListItems}
          enableColumnFilters={true}
          enableGlobalFilter={false}
          enableDensityToggle={true}
          enableRowSelection={true}
          enablePagination={false}
          enableColumnActions={false}
          enableMultiRowSelection={false}
          enableRowActions={false}
          enableBottomToolbar={false}
          muiTableBodyRowProps={({ row }: any) => ({
            onDoubleClick: () => {
              if (row.original?.category != "Birthday") {
                setRowSelection({ [row.index]: true });
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    action_list_data: row.original,
                    cancel: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Cancel",
                    },
                    no: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Save",
                      type: "primary",
                    },
                  })
                );
              }
            },
            sx: { cursor: "pointer" },
          })}
          onRowSelectionChange={setRowSelection}
          state={{
            rowSelection: rowSelection,
            pagination: { ...props.pagination },
          }}
          positionToolbarAlertBanner="bottom"
          manualPagination
          onPaginationChange={props.setPagination}
          rowCount={props.rowCount}
        />
      </div>
      <OverlayDrawer
        position="end"
        open={isOpen}
        onOpenChange={(_, { open }) => setIsOpen(open)}
        style={{ width: "450px", marginTop: "130px" }}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            {getCategoryLabel(activeRowItem.category)}
          </DrawerHeaderTitle>
        </DrawerHeader>
        <Divider className={styles.divider} />
        <DrawerBody>{Details(activeRowItemDetail)}</DrawerBody>
      </OverlayDrawer>
    </>
  );
}

export default ActionDataList;
