import { createSlice } from "@reduxjs/toolkit";

export const clientSlice=createSlice({
    name:'clientid',
    initialState:{
        storedValue:"",
    },
    reducers:{
        setClientValue:(state,action)=>{
            state.storedValue=action.payload;
        }
    }
})

export const tableValue=createSlice({
    name:'tableData',
    initialState:{
        storedValue:[],
    },
    reducers:{
        setTableData:(state,action)=>{
            state.storedValue=action.payload;
        }
    }
})

export const partnerIDs=createSlice({
    name:"partnerId",
    initialState:{
        storedValue:"",
    },
    reducers:{
        setPartnerIDs:(state,action)=>{
            state.storedValue=action.payload;
        }
    }
})

export const clientArray=createSlice({
    name:'clients',
    initialState:{
        storedValue:[]
    },
    reducers:{
        setClients:(state,action)=>{
            state.storedValue=action.payload;
        }
    }
})
export const partnerArray=createSlice({
    name:"partner",
    initialState:{
        storedValue:[]
    },
    reducers:{
        setPartners:(state,action)=>{
            state.storedValue=action.payload;
        }
    }
})
const clientSplit = createSlice({
    name: "client",
    initialState: {
      combinedData: [],
      selectedData: [],
    },
    reducers: {
      setCombinedData: (state, action) => {
        state.combinedData = action.payload;
      },
      setSelectedData: (state, action) => {
        state.selectedData = action.payload;
      },
    },
  });

export const {setCombinedData,setSelectedData}=clientSplit.actions;
export const {setClientValue}=clientSlice.actions;
export const {setTableData}=tableValue.actions;
export const {setPartnerIDs}=partnerIDs.actions;
export const {setClients}=clientArray.actions;
export const {setPartners}=partnerArray.actions;

export const clientReducer = clientSlice.reducer;
export const tableReducer = tableValue.reducer;
export const partnerIdsReducer =partnerIDs.reducer;
export const clientsArray = clientArray.reducer;
export const partnersArray = partnerArray.reducer;
export const splits = clientSplit.reducer;