import { createSlice } from '@reduxjs/toolkit';

// Define the initial state for the value
export const valueSlice = createSlice({
  name: 'value',
  initialState: {
    storedValue: '', // Default value is an empty string
  },
  reducers: {
    // Action to set the value
    setValue: (state, action) => {
      state.storedValue = action.payload; // Set the value from action payload
    },
  },
});

// Export the action so it can be dispatched
export const { setValue } = valueSlice.actions;

// Export the reducer to be included in the store
export default valueSlice.reducer;
