import * as React from "react";
import {
  makeStyles,
  Tab,
  TabList,
  shorthands,
} from "@fluentui/react-components";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { checkUserPermission, convertStringToArray } from "../../utils";
import { useNavigate, useLocation } from "react-router-dom";
import { UserPermissionsList } from "../../config";
const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    color: "#fff",
    ...shorthands.padding("50px 20px"),
    rowGap: "20px",
    margin: "0px 10px",
  },
});

const Navbar = () => {
  const styles = useStyles();
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.replace(/^\/+/, "");
  const isSystemManagerPath = currentPath.startsWith("system-manager");

  const hiddenNavItems = convertStringToArray(
    process.env.REACT_APP_HIDE_NAV_MENU
  );

  const navItems: any = [
    {
      value: "action-list",
      label: "Action List",
      link: "/action-list",
    },
    {
      value: "clients",
      label: "Clients",
      link: "/",
    },
    {
      value: "utilities",
      label: "Utilities",
      link: "/utilities",
    },
  ];

  // Conditional items based on permissions
  if (
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_CLIENT_REPORTS)
  ) {
    navItems.push({
      value: "report",
      label: "Reports",
      link: "/report",
    });
  }

  if (
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_ADD_USER) ||
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_UPDATE_USER) ||
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_VIEW_USER) ||
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_DELETE_USER)
  ) {
    navItems.push({
      value: "system-manager",
      label: "System Manager",
      link: "/system-manager/company-details",
    });
  }

  if (
    checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_COMMISSION_RECEIPTING
    ) ||
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_ADD_CLIENT) ||
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_VIEW_CLIENT) ||
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_UPDATE_CLIENT) ||
    checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_RESTRICTED_CLIENT
    ) ||
    checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_COMMISSION_DISTRIBUTION
    ) ||
    checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_COMMISSION_REPORTS
    )
  ) {
    navItems.push({
      value: "commission",
      label: "Commissions",
      link: "/commission",
    });
  }
  //   navItems.push({
  //   value: "reportdemo",
  //   label: "Report Demo",
  //   link: "/reportdemo",
  // });
  const filteredNavItems = navItems.filter(
    (item: any) => !hiddenNavItems.includes(item.value)
  );
  const handleNavigationLink = (link: any) => {
    navigate(link);
  };

  return (
    <div className={styles.root}>
      <TabList
        selectedValue={
          isSystemManagerPath
            ? "system-manager"
            : currentPath === ""
            ? "clients"
            : currentPath
        }
      >
        {filteredNavItems.map((item: any, i: number) => (
          <Tab
            key={i}
            disabled={
              !convertStringToArray(
                process.env.REACT_APP_DISABLE_NAV_MENU
              ).includes(item.value)
                ? false
                : true
            }
            value={item.value}
            onClick={() => handleNavigationLink(item.link)}
          >
            {item.label}
          </Tab>
        ))}
      </TabList>
    </div>
  );
};
export default Navbar;
