// store.ts
import { configureStore } from "@reduxjs/toolkit";
import someReducer from "./reducer";
import formSlice from "./formSlice";
import authSlice from "./authSlice";
import appSettingsSlice from "./appSettingsSlice";
import modelDialogReducer from "./modelDialogReducer";
import formUpdateReducer from "./formUpdateReducer";
import value from "./valueSlice";
import headerValue from "./headerValue"
import { clientReducer, clientsArray, partnerIdsReducer, partnersArray, splits, tableReducer } from "./clientSlice"
import tableDatas from "./tableDataSlice"
import clientPartnerSplit from "./splitSlice"
import splitData from "./dynamicResponse"
import loading from "./loader"
const store = configureStore({
  reducer: {
    someReducer: someReducer,
    formSettings: formSlice,
    authUser: authSlice,
    appSettings: appSettingsSlice,
    dialog: modelDialogReducer,
    formUpdated: formUpdateReducer,
    value:value,
    clientid:clientReducer,
    headerValue:headerValue,
    tableData:tableReducer,
    partnerId:partnerIdsReducer,
    clients:clientsArray,
    partner:partnersArray,
    client:splits,
    tableDatas: tableDatas,
    clientPartnerSplit:clientPartnerSplit,
    splitData:splitData,
    loading:loading



  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
