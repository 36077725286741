import { Select } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
interface PropsType {
  name: string;
  selectionLabel: string;
  selectFieldOptions: any[];
  newSelectOptionAdded?: any;
  selectionType: string;
  values: string;
  onChange: any;
}

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});
const defaultOptions = [createOption("")];
// const defaultOptions = [
//     createOption('One'),
//     createOption('Two'),
//     createOption('Three'),
//   ];

const CreatableSelectionList = ({
  name,
  selectionLabel,
  selectFieldOptions,
  newSelectOptionAdded,
  selectionType,
  values,
  onChange,
}: PropsType) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [value, setValue] = useState<Option | null>();

  useEffect(() => {
    if (values) {
      const newOption = createOption(values);
      setValue(newOption);
    }
  }, []);

  useEffect(() => {
    FindOption();
  }, [selectFieldOptions, name]);

  useEffect(() => {
    // console.log(options, "options added");
  }, [options]);

  const FindOption = () => {
    const foundObject = selectFieldOptions.find((obj) => obj.field === name);
    // console.log(foundObject, "Objects found");
    if (foundObject !== undefined) setOptions(foundObject.options);
  };
  console.log(options, "----options---");

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      // setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
      onChange(newOption.value);
      if (newSelectOptionAdded !== undefined) {
        newSelectOptionAdded(
          name,
          selectionLabel,
          selectionType,
          newOption,
          "add"
        );
      }
    }, 1000);
  };
  return (
    <>
      {selectionType !== "FixedList" && (
        <CreatableSelect
          styles={{
            control: (baseStyles: any, state) => ({
              ...baseStyles,
              width: 410,
            }),
          }}
          isClearable
          name={name}
          isDisabled={isLoading}
          isLoading={isLoading}
          value={value}
          options={options}
          onCreateOption={handleCreate}
          onChange={(newValue) => {
            if (newValue === null) {
              setValue({ label: "Select...", value: "" });
              if (newSelectOptionAdded) {
                newSelectOptionAdded(
                  name,
                  selectionLabel,
                  selectionType,
                  "",
                  "remove"
                );
              }
            } else {
              setValue(newValue);
              onChange(newValue.value);
            }
          }}
        />
      )}

      {selectionType === "FixedList" && (
        <Select
          // id={selectId}
          // appearance="filled-lighter"
          onChange={(e, newValue) => {
            onChange(newValue.value);
          }}
          // onBlur={formik.handleBlur}
          name={"name"}
          // disabled={fieldInfo.readOnly}
        >
          {/* <option value=""></option> */}
          {options.map((optn: any, i) => (
            <option
              key={i}
              value={optn.value}
              label={optn.label}
              selected={values === optn.value ? true : false}
            />
          ))}
        </Select>
      )}
    </>
  );
};

export default CreatableSelectionList;
