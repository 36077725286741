// Breadcrumbs.js
import React from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import "./Breadcrumbs.css"; // Import your CSS for styling
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbDivider,
  BreadcrumbProps,
  BreadcrumbButton,
  BreadcrumbLink,
} from "@fluentui/react-breadcrumb-preview";
const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const { clientId } = useParams();
  const breadcrumbNameMap: any = {
    "action-list": "Action List",
    utilities: "Utilities",
    report: "Reports",
    "system-manager": "System Manager",
    commission: "Commissions",
    "manage-user": "Manage User",
    "master-settings": "Master Settings",
    "commission-type": "Commission Type",
    "commission-rule": "Commission Rule",
    subscription: "Subscription",
    "company-detail": "Company Details",
    "income-category": "Income Categories",
    "expense-category": "Expense Categories",
    "attitude-risk-categories": "Attitude-to-Risk Categories",
    "attitude-risk-ratings": "Attitude-to-Risk Ratings",
    objectives: "Objectives",
    providers: "Providers",
    "user-defined": "User Defined",
    "case-action": "Case Action",
    "client-action": "Client Action",
    "email-template": "Email Template",
    "letter-template": "Letter Template",
    "contract-enquiry": "Contract Enquiry",
    integrations: "Integrations",
    "company-details": "Company Details",
    customisations: "Customisations",
    templates: "Templates",
  };

  const handleNavigationLink = (link: any) => {
    navigate(link);
  };
  const currentPath = location.pathname.replace(/^\/+/, "");
  return (
    <Breadcrumb aria-label="Breadcrumb">
      <BreadcrumbItem>
        <BreadcrumbButton onClick={() => handleNavigationLink("/")}>
          Home
        </BreadcrumbButton>
      </BreadcrumbItem>
      {currentPath == "" && (
        <>
          <BreadcrumbDivider />

          <BreadcrumbItem>
            <BreadcrumbButton current>Clients</BreadcrumbButton>
          </BreadcrumbItem>
        </>
      )}

      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        const displayValue =
          value === clientId ? `Client Detail ${clientId}` : value;
        return (
          <React.Fragment key={to}>
            <BreadcrumbDivider />

            <BreadcrumbItem>
              {isLast ? (
                <BreadcrumbButton current>
                  {breadcrumbNameMap[displayValue] || displayValue}
                </BreadcrumbButton>
              ) : (
                <BreadcrumbButton onClick={() => handleNavigationLink(to)}>
                  {breadcrumbNameMap[displayValue] || displayValue}
                </BreadcrumbButton>
              )}
            </BreadcrumbItem>
          </React.Fragment>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
