import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TableData = {
  liability_id: string;
  borrower: string;
  liability_type: string;
  provider: string;
  status: string;
  assignedTo: string;
  bank_account_type: string;
  bank_contribution: string;
  bank_date_opened: string;
  bank_frequency: string;
};

interface ClientState {
  storedValue: string | null; 
  partners: string[];
  clients: string[];
  tableData: TableData[]; 
}

const initialState: ClientState = {
  storedValue: null, 
  partners: [],
  clients: [],
  tableData: [],
};

const splitSlice = createSlice({
  name: "clientPartnerSplit",
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<string[]>) => {
      state.clients = action.payload;
    },
    setPartners: (state, action: PayloadAction<string[]>) => {
      state.partners = action.payload;
    },
    setTableDatas: (state, action: PayloadAction<TableData[]>) => {
      state.tableData = action.payload;
    },
    updateAssignedTo: (
        state,
        action: PayloadAction<{ rowIndex: number; value: string }>
      ) => {
        const { rowIndex, value } = action.payload;
        if (state.tableData[rowIndex]) {
          state.tableData[rowIndex].assignedTo = value;
        }
      },
      
  },
});

export const { setClients, setPartners, setTableDatas, updateAssignedTo } =
  splitSlice.actions;

export default splitSlice.reducer;
