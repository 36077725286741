// src/redux/tableDataSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TableDataState {
  tableDataList: { tableName: string; data: any[] }[];
}

const initialState: TableDataState = {
  tableDataList: [],
};

const tableDataSlice = createSlice({
  name: "tableDatas",
  initialState,
  reducers: {
    updateTableDataList: (
      state,
      action: PayloadAction<{ tableName: string; data: any[] }>
    ) => {
      const { tableName, data } = action.payload;
      const existingEntry = state.tableDataList.find(
        (entry) => entry.tableName === tableName
      );
      if (existingEntry) {
        state.tableDataList = state.tableDataList.map((entry) =>
          entry.tableName === tableName ? { ...entry, data } : entry
        );
      } else {
        state.tableDataList.push({ tableName, data });
      }
    },
  },
});

export const { updateTableDataList } = tableDataSlice.actions;

export default tableDataSlice.reducer;
