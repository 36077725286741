import { Dispatch, SetStateAction } from "react";
import { SearchBox } from "@fluentui/react-search-preview";
import type { SearchBoxProps } from "@fluentui/react-search-preview";
import { Dismiss16Regular } from "@fluentui/react-icons";

interface PropsType {
  setSearch?: Dispatch<SetStateAction<string>>;
  search?: string;
  isLoading?: boolean;
  placeholder?: string;
}

const Search = ({ setSearch, search, isLoading, placeholder }: PropsType) => {
  const handleSearch: SearchBoxProps["onChange"] = (ev, data) => {
    setSearch && setSearch(data.value);
  };
  return (
    <div data-testid="search">
      <SearchBox
        value={search}
        onChange={handleSearch}
        disabled={isLoading ? true : false}
        data-testid="search"
        placeholder={placeholder ? placeholder : ""}
        dismiss={
          search !== "" ? (
            <>
              <span
                onClick={() => {
                  setSearch && setSearch("");
                }}
              >
                <Dismiss16Regular />
              </span>
            </>
          ) : null
        }
      />
    </div>
  );
};

export default Search;
