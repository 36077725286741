import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dynamicResponseData: { asset: [], liability: [],policy:[] ,income:[] ,expense:[] ,dependant:[], addressbook:[] },
};

const splitResponse = createSlice({
  name: "splitData",
  initialState,
  reducers: {
    setDynamicResponseData: (state, action) => {
      if (
        JSON.stringify(state.dynamicResponseData) !==
        JSON.stringify(action.payload)
      ) {
        state.dynamicResponseData = action.payload;
      }
    },
  },
});

export const { setDynamicResponseData } = splitResponse.actions;

export default splitResponse.reducer;