import "./style.css";
import {
  Dropdown,
  makeStyles,
  Option,
  shorthands,
  useId,
} from "@fluentui/react-components";

// import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

interface PropsType {
  placeholder: string;
  multiselect?: boolean;
  disabled?: boolean;
  selectedOptions?: [];
  onOptionSelect?: (event: any, option: string | any) => void;
  values: {
    value: any;
    label: string;
  }[];
}

const useStyles = makeStyles({
  root: {
    width: "100",
    height: "100",
  },
});

const DropDownBox = ({
  placeholder,
  values,
  onOptionSelect,
  multiselect,
  disabled,
  selectedOptions,
}: PropsType) => {
  const styles = useStyles();
  return (
    <div>
      <Dropdown
        // aria-labelledby={dropdownId}
        disabled={disabled ? disabled : undefined}
        onOptionSelect={onOptionSelect}
        multiselect={multiselect ? multiselect : undefined}
        placeholder={placeholder}
        appearance="outline"
        selectedOptions={selectedOptions ? selectedOptions : undefined}
        style={{ borderColor: disabled ? undefined : "grey", minWidth: "auto" }}
      >
        {values.length > 0 &&
          values.map((item, i) => {
            return (
              <Option key={i} value={item.value}>
                {item.label}
              </Option>
            );
          })}
      </Dropdown>
    </div>
  );
};

export default DropDownBox;

// styles={{
//   icon: {color: 'white', fontSize: 72},
//   root: {
//     width: 100,
//     height: 100,
//     backgroundColor: 'black',
//     selectors: {
//       ':hover .ms-Button-icon': {
//         color: 'red'
//       },
//       ':active .ms-Button-icon': {
//         color: 'yellow'
//       }
//     }
//   },
