import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
} from "@fluentui/react-components";
import React, { useEffect, useState } from "react";

import InitialPage from "./InitialPage";
import TitleDescription from "./TitleDescription";
import FieldSelectionPage from "./FieldSelectionPage";
import FinishPage from "./FinishPage";
import apiServices from "../../../service";
import store from "../../../redux/store";
interface MainProps {
  isOpen: boolean;
  onClose: () => void;
}
interface Client {
  id: string;
  fname: string;
}

const MainPage: React.FC<MainProps> = ({ isOpen, onClose }) => {
  const userId = store.getState().authUser.userId;
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClients, setSelectedClients] = useState<Client[]>([]);
  const [specificationList, setSpecificationList] = useState<any>([]);
  const [selectedSpecId, setSelectedSpecId] = useState<any>(null);
  const [selectedClientIds, setSelectedClientIds] = useState<string[]>([]);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [finalTitle, setFinalTitle] = useState<string>("");
  const [finalDescription, setFinalDescription] = useState<string>("");

  const [active, setActive] = React.useState<
    "FIRST" | "SECOND" | "THIRD" | "FOURTH" | null | ""
  >("FIRST");
  const clickToTitle = () => {
    setActive("THIRD");
  };
  const BackToFirst = () => {
    setActive("FIRST");
  };
  const cancelFirst = () => {
    onClose();
    setSelectedClients([]);
    setSelectedClientIds([]);
    setTitle("");
    setDescription("");
    setFinalTitle("");
    setFinalDescription("");
    setSelectedSpecId(null);
  };
  const cancelSelection = () => {
    onClose();
    setSelectedClients([]);
    setSelectedClientIds([]);
    setTitle("");
    setDescription("");
    setFinalTitle("");
    setFinalDescription("");
    setSelectedSpecId(null);
  };
  const MoveToSelection = () => {
    setActive("THIRD");
  };
  const BacktoSecond = () => {
    setActive("FIRST");
  };
  const CancelFieldSelection = () => {
    onClose();
    setSelectedClients([]);
    setSelectedClientIds([]);
    setTitle("");
    setDescription("");
    setFinalTitle("");
    setFinalDescription("");
    setSelectedSpecId(null);
  };
  const CancelTitlePage = () => {
    onClose();
    setSelectedClients([]);
    setSelectedClientIds([]);
    setTitle("");
    setDescription("");
    setFinalTitle("");
    setFinalDescription("");
    setSelectedSpecId(null);
  };
  const CancelFinishPage = () => {
    onClose();
    setSelectedClients([]);
    setSelectedClientIds([]);
    setTitle("");
    setDescription("");
    setFinalTitle("");
    setFinalDescription("");
    setSelectedSpecId(null);
  };
  const BackToFieldSelection = () => {
    setActive("THIRD");
  };
  const NextToFinalPage = () => {
    setActive("FOURTH");
  };
  const BackToTab = () => {
    onClose();
  };

  const editClick = () => {
    const selectedSpec = specificationList.find(
      (spec: any) => spec.SearchId === selectedSpecId
    );
    if (selectedSpec) {
      setTitle(selectedSpec.Title || "");
      setDescription(selectedSpec.Description || "");

      const condition = JSON.parse(selectedSpec.Condition);

      const selectedClients = condition.map((id: any) => {
        const client = clients.find((client: Client) => client.id === id);
        const fname = client ? client.fname : "";
        return {
          id,
          fname,
        };
      });

      setSelectedClients(selectedClients);
      setSelectedClientIds(condition.customer_ids);
      setActive("THIRD");
    } else {
      console.warn("Selected specification not found");
    }
  };

  useEffect(() => {
    if (isOpen) {
      setActive("FIRST");
    }
  }, [isOpen]);
  const extractGetList = () => {
    apiServices.extract
      .getExtract()
      .then((res) => {
        const filteredResponse = res.data?.data.filter(
          (item: any) => item.Type === "Selection"
        );

        if (filteredResponse.length === 0) {
          console.warn("No entries with Type: 'Selection' found.");
        }

        setSpecificationList(filteredResponse);
      })
      .catch((error) => {
        console.error("Error fetching extract list:", error);
      });
  };

  useEffect(() => {
    extractGetList();
  }, []);

  const extractClientList = () => {
    apiServices.client.getClients().then((res) => {});
  };
  useEffect(() => {
    // extractClientList();
  }, []);
  const deleteSpecification = (id: any) => {
    apiServices.extract
      .delete(id)
      .then((res: any) => {
        extractGetList();
        setSelectedSpecId(null)

      })
      .catch((error) => {
        console.error("Error during delete:", error);
      });
  };
  const UpdateListing = () => {
    const data = {
      title: title ? title : finalTitle,
      description: description ? description : finalDescription,
      condition: selectedClientIds,
      UserId: userId,
      type: "Selection",
    };
    apiServices.exportExcel
      .updateList(data, selectedSpecId)
      .then(() => {})
      .catch((error) => {
        console.error("Error during update:", error);
      });
    extractGetList();
  };
  const AddSelection = () => {
    if (selectedSpecId) {
      UpdateListing();
      extractGetList();
      onClose();
      extractGetList();
    } else {
      const data = {
        title: title ? title : finalTitle,
        description: description ? description : finalDescription,
        condition: selectedClientIds,
        UserId: userId,
        type: "Selection",
      };
      apiServices.exportExcel
        .addList(data)
        .then(() => {})
        .catch((error) => {
          console.error("Error during selection:", error);
        });
      extractGetList();
      onClose();
      extractGetList();
      setTitle("");
      setFinalTitle("");
      setDescription("");
      setSelectedSpecId(null)
    }
  };

  return (
    <div>
      {" "}
      <Dialog
        modalType="alert"
        open={isOpen}
        onOpenChange={(event, data) => {
          if (!data.open) onClose();
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              {active === "FIRST" && (
                <InitialPage
                  clickToTitle={clickToTitle}
                  cancelFirst={cancelFirst}
                  BackToTab={BackToTab}
                  specificationList={specificationList}
                  setSelectedSpecId={setSelectedSpecId}
                  selectedSpecId={selectedSpecId}
                  deleteSpecification={deleteSpecification}
                  editClick={editClick}
                />
              )}
              {/* {active === "SECOND" && (
                <TitleDescription
                  MoveToSelection={MoveToSelection}
                  BackToFirst={BackToFirst}
                  CancelTitlePage={CancelTitlePage}
                  title={title}
                  setTitle={setTitle}
                  setDescription={setDescription}
                  description={description}
                />
              )} */}
              {active === "THIRD" && (
                <FieldSelectionPage
                  BacktoSecond={BacktoSecond}
                  CancelFieldSelection={CancelFieldSelection}
                  NextToFinalPage={NextToFinalPage}
                  setSelectedClientIds={setSelectedClientIds}
                  setSelectedClients={setSelectedClients}
                  selectedClients={selectedClients}
                  clients={clients}
                  setClients={setClients}
                  title={title}
                  setTitle={setTitle}
                  setDescription={setDescription}
                  description={description}
                />
              )}
              {active === "FOURTH" && (
                <FinishPage
                  selectedClients={selectedClients}
                  CancelFinishPage={CancelFinishPage}
                  BackToFieldSelection={BackToFieldSelection}
                  title={title}
                  description={description}
                  setDescription={setDescription}
                  setTitle={setTitle}
                  finalTitle={finalTitle}
                  setFinalTitle={setFinalTitle}
                  finalDescription={finalDescription}
                  setFinalDescription={setFinalDescription}
                  finishSelection={AddSelection}
                />
              )}
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default MainPage;
