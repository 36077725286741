import { createSlice } from "@reduxjs/toolkit"

export const tableHeader=createSlice({
    name:'headerValue',
    initialState:{
        storedValue:""
    },
    reducers:{
        setHeaderValue:(state,action)=>{
            state.storedValue=action.payload
        }
    }
})
export const {setHeaderValue}=tableHeader.actions;
export default tableHeader.reducer;