import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Option, Spinner } from "@fluentui/react-components";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { setClients, setPartners } from "../../redux/clientSlice";
import { setDynamicResponseData } from "../../redux/dynamicResponse";

type TableData = {
  recordId: any;
  borrower: string;
  liability_type: string;
  provider: string;
  status: string;
  assignedTo: string;
  bank_account_type: string;
  bank_contribution: string;
  bank_date_opened: string;
  bank_frequency: string;
  case_type: string;
  assured_detail: string;
  assured_name1: string;
  income_type: string;
  owner: string;
  frequency: string;
  source: string;
  sort_order:any;
  client_amount:any;
  partner_amount:any;
  joint_amount:any;
  first_names:string;
  financially_dependant:string;
  known_as:string;
  last_name:string;
  address_type:string;
  organisation:string;
  contact_name:string;
  address_1:string;
  address_2:string;
  address_3:string;
  postcode:string;
};

export const Example = () => {
  const [assignedTo, setAssignedTo] = useState<any>();
  const [dropdownValue, setDropDownValue] = useState("Not Assigned");
  const dispatch: AppDispatch = useDispatch();
  const tableDatas = useSelector(
    (state: any) => state.tableData.storedValue || []
  );
  const tableNames = useSelector((state: any) => state.headerValue.storedValue);
  const partnerId = useSelector((state: any) => state.partnerId.storedValue);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const clientValue = useSelector((state: any) => state.clientid.storedValue);
  const loader = useSelector((state: any) => state.loading.loadingTableData);
  const dynamicResponseData = useSelector(
    (state: any) => state.splitData.dynamicResponseData
  );
  useEffect(() => {
    if (Array.isArray(tableDatas)) {
      const updatedData = tableDatas.map((item: any) => ({
        ...item,
        assignedTo: item.assignedTo || "Not Assigned",
      }));
      setTableData(updatedData);
    } else {
      setTableData([]);
    }
  }, [tableDatas, tableNames]);

  const handleDropdownChange = (value: string, rowIndex: number) => {
    const updatedData = [...tableData];

    updatedData[rowIndex].assignedTo = value || "Not Assigned";

    setTableData(updatedData);

    const currentTableName = tableNames.toLowerCase();

    const dynamicResponse = updatedData.flatMap((item) => {
      if (item.assignedTo === "Both") {
        return [
          {
            ...item,
            assignedTo: "Client",
            assignmentType: item.assignedTo,
            tableName: tableNames,
          },
          {
            ...item,
            assignedTo: "Partner",
            assignmentType: item.assignedTo,
            tableName: tableNames,
          },
        ];
      }
      return [
        {
          ...item,
          assignedTo: item.assignedTo,
          assignmentType: item.assignedTo,
          tableName: tableNames,
        },
      ];

      if (item.assignedTo === "Client" || item.assignedTo === "Partner") {
        return [
          {
            ...item,
            assignedTo: item.assignedTo,
            assignmentType:
              item.assignedTo === "Client" ? clientValue : partnerId,
            tableName: tableNames,
          },
        ];
      }

      return [];
    });

    const previousData = dynamicResponseData[currentTableName] || [];

    const filteredData = previousData.filter(
      (existingItem: any) =>
        existingItem.recordId !== updatedData[rowIndex].recordId
    );

    const newTableData = [...filteredData, ...dynamicResponse];

    const uniqueTableData = newTableData.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.recordId === item.recordId && t.assignedTo === item.assignedTo
        )
    );

    const newDynamicResponseData = {
      ...dynamicResponseData,
      [currentTableName]: uniqueTableData,
    };

    dispatch(setDynamicResponseData(newDynamicResponseData));
  };

  const columns = useMemo<MRT_ColumnDef<TableData>[]>(() => {
    const dropdownCell = ({ row }: { row: any }) => {
      const rowIndex = row.index;
      const recordId = tableData[rowIndex]?.recordId;
      const tableSpecificData =
        dynamicResponseData?.[tableNames.toLowerCase()] || [];
      const assignedToValue =
        tableSpecificData.find((item: any) => item.recordId === recordId)
          ?.assignmentType || "Not Assigned";

      return (
        <Dropdown
          key={rowIndex}
          placeholder="Select an option"
          value={assignedToValue}
          onOptionSelect={(_, data) => {
            handleDropdownChange(data.optionValue as string, rowIndex);
          }}
        >
          <Option value="Not Assigned">Not Assigned</Option>
          <Option value="Client">Client</Option>
          <Option value="Partner">Partner</Option>
          <Option value="Both">Both</Option>
        </Dropdown>
      );
    };

    if (tableNames === "Liability") {
      return [
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
        { accessorKey: "borrower", header: "Borrower" },
        { accessorKey: "liability_type", header: "Liability Type" },
        { accessorKey: "provider", header: "Provider" },
        { accessorKey: "status", header: "Status" },
      ];
    } else if (tableNames === "Asset") {
      return [
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
        { accessorKey: "case_type", header: "Account Type" },
        { accessorKey: "bank_contribution", header: "Bank" },
        { accessorKey: "bank_date_opened", header: "Date Opened" },
        { accessorKey: "bank_frequency", header: "Frequency" },
      ];
    } else if (tableNames === "Policy") {
      return [
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
        { accessorKey: "assured_detail", header: "Assured Detail" },
        { accessorKey: "assured_name1", header: "Assured Name" },
        { accessorKey: "provider", header: "Provider" },
      ];
    } else if (tableNames === "Income") {
      return [
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
        { accessorKey: "owner", header: "Owner" },
        { accessorKey: "income_type", header: "Type" },
        { accessorKey: "frequency", header: "Frequency" },
        { accessorKey: "source", header: "Source" },
      ];
    }
    else if (tableNames === "Expense"){
      return[
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
        { accessorKey: "sort_order", header: "Sort Order" },
        { accessorKey: "client_amount", header: "Client Amount" },
        { accessorKey: "partner_amount", header: "Partner Amount" },
        { accessorKey: "joint_amount", header: "Joint Amount" },
        { accessorKey: "frequency", header: "Frequency" },

      ]
    }
    else if(tableNames === "Dependant"){
      return[
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
        { accessorKey: "first_names", header: "First Name" },
        {accessorKey:"last_name", header:"Last Name"},
        { accessorKey: "financially_dependant", header: "Financially Dependant" },
        { accessorKey: "known_as", header: "Known As" },

      ]
    }
    else if(tableNames === "Address"){
      return[
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
        { accessorKey: "address_type", header: "Address Type" },
        {accessorKey:"organisation", header:"Organisation"},
        { accessorKey: "contact_name", header: "Contact Name" },
        { accessorKey: "address_1", header: "Address 1" },
        { accessorKey: "address_2", header: "Address 2" },
        { accessorKey: "address_3", header: "Address 3" },
        { accessorKey: "postcode", header: "Postcode" },


      ]
    }

    return [];
  }, [tableData, tableNames]);

  return (
    <>
      {loader ? (
        <div className="spinner-container">
        <Spinner size="medium" />
      </div>
       
      ) : (
        <div
          style={{
            marginTop: "-56px",
            overflow:'hidden'
          }}
        >
          <MaterialReactTable
            columns={columns}
            data={tableData}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={true}
            initialState={{
              pagination: {
                pageSize: 5, // Set initial rows per page to 5
                pageIndex: 0, // Set initial page index to 0 (first page)
              },
            }}
            enableSorting={false}
            enableGlobalFilter={false}
            enableColumnOrdering={false}
            enableColumnResizing={false}
            enableColumnFilterModes={false}
            enableExpandAll={false}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableFilters={false}
            enableHiding={false}
            muiTableBodyCellProps={({ column }) => ({
              style: {
                padding: "6px 12px",
                fontSize: "0.875rem",
                overflow: "visible",
                ...(column.id === "assignedTo" && {
                  width: "280px",
                  maxWidth: "280px",
                }),
              },
            })}
            muiTableProps={{
              style: {
                margin: "0",
                padding: "0",
                border: "1px solid rgba(81, 81, 81, .5)",
                overflow: "visible",
                position: "relative",
              },
            }}
            muiTableContainerProps={{
              style: {
                minHeight: "200px",
                maxHeight: "400px",
                overflow: "auto",
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default Example;
